import { useTranslation } from "react-i18next";

const Team = ({ title, description, members }) => {
  const { i18n } = useTranslation("translation");
  const lang = i18n.resolvedLanguage;

  return (
    <div className="bg-white mt-24 py-24 sm:py-32" id="team">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title[lang]}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {description[lang]}
          </p>
        </div>
        <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {members.map((member) => (
            <li key={member.name[lang]}>
              <img
                className="aspect-[1/1] w-full rounded-2xl object-cover"
                src={member.image.src}
                alt={member.image.title}
              />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {member.name[lang]}
              </h3>
              <p className="text-base leading-7 text-gray-600">
                {member.position[lang]}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Team;

import { useTranslation } from "react-i18next";

const Services = ({ title, description, services }) => {
  const { i18n } = useTranslation("translation");
  const lang = i18n.resolvedLanguage;

  return (
    <div className="bg-white py-24 sm:py-32" id="services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title[lang]}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {description[lang]}
          </p>
        </div>
        <ul className="mx-auto mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {services.map((service) => (
            <li key={service.name[lang]}>
              <a
                href={service.link[lang]}
                target="_blank"
                rel="noreferrer"
                key={service.name}
              >
                <div
                  className="relative group isolate flex flex-col justify-end overflow-hidden rounded-2xl px-8 pb-8 pt-40 max-w-sm mx-auto hover:bg-opacity-90 transition duration-300 ease-in-out cursor-pointer"
                  style={{ minHeight: 300 }}
                >
                  <img
                    src={service.image.src}
                    alt={service.image.title}
                    className="absolute inset-0 h-full w-full object-cover transition duration-300 ease-in-out"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t blur-md from-gray-900 via-gray-900/40"></div>

                  {/* This is the service name that hides on hover */}
                  <h3 className="z-10 mt-3 text-3xl font-bold text-white group-hover:hidden transition duration-300 ease-in-out">
                    {service.name[lang]}
                  </h3>

                  {/* This is the service description that shows on hover */}
                  <div className="z-10 gap-y-1 overflow-hidden text-base leading-6 text-gray-300 hidden group-hover:inline-block absolute transition duration-300 ease-in-out">
                    {service.description[lang]}
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Services;

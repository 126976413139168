import React from "react";
import { useTranslation } from "react-i18next";

const Subsidiaries = ({ title, description, subsidiaries }) => {
  const { i18n } = useTranslation("translation");
  const lang = i18n.resolvedLanguage;

  return (
    <div className="py-12">
      <div className="bg-white py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <a
              href="https://firebasestorage.googleapis.com/v0/b/mansour-holding-group.appspot.com/o/Mansour-Profile.pdf?alt=media&token=9a42d55f-aa53-4795-bd08-f282e811d0b0&_gl=1*zq2end*_ga*Mzk2ODM3MTM4LjE2NzAzMjk1NjM.*_ga_CW55HF8NVT*MTY5Nzg4MDU1Ni4xMDQuMS4xNjk3ODgwNTY1LjUxLjAuMA.."
              target="_blank"
              rel="noreferrer"
              className="flex flex-col sm:flex-row gap-6"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/mansour-holding-group.appspot.com/o/subsidiary%20logos%20square%2FMHG%20Logos%20Main.png?alt=media&token=f3530ddc-8047-4959-9330-28e9342c30b5"
                alt="mhg-logo"
                className="w-72 self-center"
              />
              <h2 className="text-3xl  mt-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title[lang]}
              </h2>
            </a>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {description[lang]}
            </p>
          </div>
          <ul className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-3 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
            {subsidiaries.map((subsidiary) => (
              <li
                key={subsidiary.name[lang]}
                className="flex flex-col gap-5 xl:flex-row items-start"
              >
                <a
                  href={subsidiary.link[lang]}
                  target="_blank"
                  rel="noreferrer"
                  className="w-full md:w-48 flex-none object-contain"
                >
                  <img
                    className="w-full md:w-48 flex-none object-contain"
                    src={subsidiary.logo.src}
                    alt=""
                  />
                </a>
                <div className="flex-auto">
                  <a
                    href={subsidiary.link[lang]}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-col"
                  >
                    <h3 className="text-lg font-bold leading-8 tracking-tight text-gray-900">
                      {subsidiary.name[lang]}
                    </h3>
                    <p className="text-base leading-7 italic text-gray-700">
                      {subsidiary.tagline[lang]}
                    </p>
                  </a>
                  <p className="mt-6 text-sm leading-6 text-gray-600">
                    {subsidiary.description[lang]}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Subsidiaries;

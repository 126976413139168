import { Fragment, useEffect, useState } from "react";

import "./i18n";

import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { Helmet } from "react-helmet-async";

import Team from "./components/Team";
import About from "./components/About";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Subsidiaries from "./components/Subsidiaries";
import Header from "./components/Header";

const App = () => {
  const { t, i18n } = useTranslation("translation");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState({});
  const [members, setMembers] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [services, setServices] = useState([]);

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCDE0eoZAWFJqnx-9L63p1DpeFnSGX7pCw",
    authDomain: "mansour-holding-group.firebaseapp.com",
    projectId: "mansour-holding-group",
    storageBucket: "mansour-holding-group.appspot.com",
    messagingSenderId: "391823398076",
    appId: "1:391823398076:web:d04d8f11357419df93acb9",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const initializeData = async () => {
    try {
      const contentCollection = collection(db, "content");
      const contentDoc = doc(contentCollection, "general");
      const contentSnapshot = await getDoc(contentDoc);
      setContent(contentSnapshot.data());

      const subsidiariesCollection = collection(db, "subsidiaries");
      const subsidiariesQuery = query(
        subsidiariesCollection,
        orderBy("order", "asc")
      );
      const subsidiariesSnapshot = await getDocs(subsidiariesQuery);
      let subsidiariesList = [];
      subsidiariesSnapshot.forEach((doc) => subsidiariesList.push(doc.data()));
      setSubsidiaries(subsidiariesList);

      const servicesCollection = collection(db, "services");
      const servicesQuery = await query(
        servicesCollection,
        orderBy("order", "asc")
      );
      const servicesSnapshot = await getDocs(servicesQuery);
      let servicesList = [];
      servicesSnapshot.forEach((doc) => servicesList.push(doc.data()));
      setServices(servicesList);

      const membersCollection = collection(db, "members");
      const membersQuery = await query(
        membersCollection,
        orderBy("order", "asc")
      );
      const membersSnapshot = await getDocs(membersQuery);
      let membersList = [];
      membersSnapshot.forEach((doc) => membersList.push(doc.data()));
      setMembers(membersList);
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    initializeData();
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen transition-all ease-in-out duration-700">
        <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
          <svg
            fill="none"
            className="w-6 h-6 animate-spin"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>

          <div>Loading ...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-red-500">Error</h1>
          <p className="text-lg text-gray-700">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <html
          lang={i18n.language}
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
        />
        <meta charSet="utf-8" />
        <title>{t("pageTitle")}</title>
        <meta name="description" content={t("pageDescription")} />
      </Helmet>

      <div className="bg-white" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
        <main>
          <Header />
          <Hero video={content.heroVideo} textOverlay={content.heroTitle} />
          <Subsidiaries
            title={content.portfolioCompaniesTitle}
            description={content.portfolioCompaniesDescription}
            subsidiaries={subsidiaries}
          />
          <About
            title={content.aboutSectionTitle}
            description={content.aboutSectionDescription}
            subsectionTitle={content.aboutSubSectionTitle}
            subsectionDescription={content.aboutSubSectionDescription}
            bannerImage={content.aboutSectionImage}
            singleSideImage={content.aboutSectionSingleSideImage}
            centerTopImage={content.aboutSectionCenterTopImage}
            centerBottomImage={content.aboutSectionCenterBottomImage}
            sideTopImage={content.aboutSectionSideTopImage}
            sideBottomImage={content.aboutSectionSideBottomImage}
          />
          <Services
            title={content.servicesTitle}
            description={content.servicesDescription}
            services={services}
          />
          <Team
            title={content.teamTitle}
            description={content.teamDescription}
            members={members}
          />

          <Contact
            title={content.contactTitle}
            description={content.contactDescription}
            contactAddress={content.contactAddress}
            contactPhone={content.contactPhone}
            contactEmail={content.contactEmail}
            db={db}
          />
        </main>

        <Footer
          facebook={content.facebook}
          instagram={content.instagram}
          linkedIn={content.linkedin}
          twitter={content.twitter}
        />
      </div>
    </Fragment>
  );
};

export default App;
